import { Location } from "@angular/common";
import { Component, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CreditCardFailedComponent } from "@app/shared/components/credit_card_failed/credit_card_failed.component";
import { StoreService } from "@app/shared/services/store.service";
import { UserService } from "@app/shared/services/user.service";
import { Store } from "@models/store";
import { User } from "@models/user";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MenuButtonsService } from "../../services/menu_buttons.service";
import { AuthService } from "@app/auth/services/auth.service";
import { NavLink } from "@models/navlink";
import { StoreNotification } from "@models/store_notification";
import { environment } from "@environments/environment";
import {StorageService} from "@app/shared/services/storage.service";

declare const ga;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "main-menu",
  styleUrls: ["./main_menu.component.scss"],
  templateUrl: "./main_menu.component.html",
})
export class MainMenuComponent {
  menuButtons: TemplateRef<any>;
  user: User;
  store: Store;

  mainNavLinks: NavLink[];

  gaLoaded: boolean;
  onlyEmailLists: boolean;

  constructor(
      private router: Router,
      private userService: UserService,
      protected modalService: NgbModal,
      protected location: Location,
      private storeService: StoreService,
      private menuButtonsService: MenuButtonsService,
      private authService: AuthService,
      private storageService: StorageService,
  ) {
  }

  ngOnInit() {
    this.user = this.userService.getSource().getValue();
    this.store = this.storeService.getSource().getValue();
    this.onlyEmailLists = true;
    this.loadGA();
    this.mainNavLinks = this.getLinkMap();

    this.menuButtonsService
        .getSource()
        .subscribe((menuButtons: TemplateRef<any>) => {
          this.menuButtons = menuButtons;
        });
    if (this.store.isCreditCardFailed) {
      this.openCreditCardFailedModal();
    }
  }

  getLinkMap(): NavLink[] {
    return [
      {
        label: "Performance",
        icon: ["fal", "chart-line"],
        url: "/",
      },
      {
        label: "Marketing",
        icon: ["fal", "megaphone"],
        children: [
          {
            label: "Email Campaigns",
            icon: ["fas", "envelope"],
            url: "/email_campaigns",
            parentLabel: "Marketing",
          },
          {
            label: "Ads",
            hidden: !this.store.package.dynamicAdsEnabled && !this.store.package.staticAdsEnabled,
            url: "/ads/dashboard",
            icon: ["fas", "ad"],
            parentLabel: "Marketing",
          },
          {
            label: "Automations",
            url: "/automations/flows",
            icon: ["fas", "cog"],
            parentLabel: "Marketing",
          },
          {
            label: "Coupons",
            url: "/unique_coupon_campaigns",
            icon: ["fas", "coin"],
            hidden: !this.store.package.uniqueCouponCodesEnabled,
            parentLabel: "Marketing",
          },
          {
            url: "/onsite_abandonment",
            label: "Onsite Abandonment",
            icon: ['fas', 'user-tag'],
            hidden: !this.store.package.onsiteAbandonmentEnabled,
            parentLabel: "Marketing",
          },
          {
            url: "/calendar",
            label: "Marketing Calendar",
            icon: ['fas', 'calendar'],
            hidden: this.store.milestones.hasActions == false,
            parentLabel: "Marketing",
          },
        ]
      },
      {
        label: "Audience",
        icon: ["fal", "users"],
        children: [
          {
            label: "Email Lists",
            url: "/segments/email_lists/index",
            icon: ['fas', 'at'],
            parentLabel: "Audience",
          },
          {
            label: "Dynamic Lists",
            url: "/segments/dynamic/index",
            icon: ['fas', 'list'],
            hidden: this.store.package.dynamicSegmentLimit == 0,
            parentLabel: "Audience",
          },
          {
            label: "Segments",
            url: "/segments/custom/index",
            icon: ['fas', 'users'],
            hidden: !this.store.package.segmentsEnabled,
            parentLabel: "Audience",
          },
          {
            label: "Sign Up Forms",
            url: "/forms",
            icon: ["fas", "clipboard"],
            parentLabel: "Audience",
          },
        ]
      },
    ];
  }

  openCreditCardFailedModal(): void {
    if (window.location.pathname === "/account") {
      return;
    }
    const modalRef = this.modalService.open(CreditCardFailedComponent, {
      size: "md",
      beforeDismiss: () => false, // blocks the modal from being dismissable
    });
    modalRef.componentInstance.creditCardFailedAmount =
        this.store.creditCardFailedAmount;
    modalRef.componentInstance.currencyCode = this.store.currencyCode;
  }

  logout(): void {
    this.authService.logout().subscribe((response) => {
      this.router.navigateByUrl('/login');
    });
  }

  loadGA(): void {
    if (this.gaLoaded) {
      return;
    }
    this.gaLoaded = true; // Only attempt to load GA once.

    if (!environment.gaTrackingId) {
      console.warn("Google Analytics not loaded - missing GA_TRACKING_ID");
      return;
    }

    // This ugly snippet was provided by google
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date().getTime();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', environment.gaTrackingId, 'app.springbot.com');

    const gaDimensions = {};

    if (environment.production) {
      gaDimensions['dimension3'] = this.router.url;
    }

    if (environment.production && this.user) {
      gaDimensions['dimension10'] = this.user.id;
      gaDimensions['dimension2'] = this.user.trueUserAdmin;
    }

    if (environment.production && this.store) {
      gaDimensions['dimension1'] = this.store.id;
      gaDimensions['dimension7'] = this.store.accountKey;
      gaDimensions['dimension8'] = this.store.package.packageName;
      gaDimensions['dimension13'] = this.store.accountKey;
      gaDimensions['dimension7'] = this.store.accountKey;
    }

    this.storageService.setItem('sbGaDimensions', JSON.stringify(gaDimensions));
    for (const dimension in gaDimensions) {
      ga('set', dimension, gaDimensions[dimension]);
    }
    ga('send', 'pageview');
  }
}
