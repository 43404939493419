<div class="iphone">
  <div class="iphone-top">
    <span class="speaker"></span>
    <span class="camera"></span>
  </div>
  <div class="iphone-screen">
    <iframe #iframe class="border-0 h-100 w-100"></iframe>
  </div>
  <div class="buttons">
    <span class="on-off"></span>
    <span class="sleep"></span>
    <span class="up"></span>
    <span class="down"></span>
  </div>
</div>
