<div class="row my-3">
  <div class="col-12 text-center">
    <div class="d-flex justify-content-center">
      <!-- Custom Legend for 'Industry Average' and 'Your Store' -->
      <div class="legend-item">
        <fa-icon [icon]="['fas', 'square']" style="color:#0092db;margin-right:4px;"></fa-icon>
        <span class="legend-text">Industry Average</span>
      </div>
      <div class="legend-item ml-3">
        <fa-icon [icon]="['fas', 'square']" style="color:#3EB049;margin-right:4px;"></fa-icon>
        <span class="legend-text">Your Store</span>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <!-- Chart for 'Opens' and 'Clicks' -->
  <div class="col-md-6 pb-3">
    <div class="row" style="margin:5px">
      <div class="col-6 text-center font-weight-bold">
        {{ tooltipLabels[0] }}
      </div>
      <div class="col-6 text-center font-weight-bold">
        {{ tooltipLabels[1] }}
      </div>
    </div>
    <canvas baseChart
            [datasets]="barChartDataOpensClicks.datasets"
            [labels]="barChartDataOpensClicks.labels"
            [options]="barChartOptions"
            [type]="barChartType"
            [legend]="false">
    </canvas>
    <div class="row" style="margin:5px">
      <div class="col-6 text-center">
        {{ barChartLabels[0] }}
      </div>
      <div class="col-6 text-center">
        {{ barChartLabels[1] }}
      </div>
    </div>
  </div>

  <!-- Chart for 'Bounces' and 'Unsubscribes' -->
  <div class="col-md-6 pb-3">
    <div class="row" style="margin:5px">
      <div class="col-6 text-center font-weight-bold">
        {{ tooltipLabels[2] }}
      </div>
      <div class="col-6 text-center font-weight-bold">
        {{ tooltipLabels[3] }}
      </div>
    </div>
    <canvas baseChart
            [datasets]="barChartDataBouncesUnsubscribes.datasets"
            [labels]="barChartDataBouncesUnsubscribes.labels"
            [options]="barChartOptions"
            [type]="barChartType"
            [legend]="false">
    </canvas>
    <div class="row" style="margin:5px">
      <div class="col-6 text-center">
        {{ barChartLabels[2] }}
      </div>
      <div class="col-6 text-center">
        {{ barChartLabels[3] }}
      </div>
    </div>
  </div>
</div>
