<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-md-6">
        <h5 class="blue-dark-color">Email Performance</h5>
      </div>
      <div class="col-12 col-md-6 text-right" *ngIf="embedded">
        <a routerLink="/performance/channels/email_campaigns">
          View full analysis
          <span> <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon> </span>
        </a>
      </div>
      <div class="col-12 col-md-6 text-right" *ngIf="!filterOverride">
        <div class="field" style="margin-bottom:0">
          <div ngbDropdown class="d-inline-block w-100">
            <button type="button" class="btn btn-outline-primary w-100" id="startDateDropdown" ngbDropdownToggle>{{ filterLabel }}</button>
            <div ngbDropdownMenu aria-labelledby="startDateDropdown" class="w-100">
              <button (click)="setFilter({ startDate: '7_days_ago', label: 'Last 7 Days' })" ngbDropdownItem>Last 7 Days</button>
              <button (click)="setFilter({ startDate: '30_days_ago', label: 'Last 30 Days' })" ngbDropdownItem>Last 30 Days</button>
              <button (click)="setFilter({ startDate: '90_days_ago', label: 'Last 90 Days' })" ngbDropdownItem>Last 90 Days</button>
              <button (click)="setFilter({ startDate: 'ytd', label: 'Year to Date' })" ngbDropdownItem>Year to date</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-campaign-industry-average-bar-chart
        [storeData]="storeData"
        [barChartLabels]="barChartLabels"
      *ngIf="loaded">
    </app-campaign-industry-average-bar-chart>
    <div *ngIf="!loaded" class="row">
      <div class="col-12 text-center p-5">
        <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="2x" class="green-color"></fa-icon>
      </div>
    </div>
  </div>
</div>
