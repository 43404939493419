import { Component, TemplateRef, ViewChild } from "@angular/core";
import { UserService } from "@app/shared/services/user.service";
import { StoreService } from "@app/shared/services/store.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { Store } from "@models/store";
import { User } from "@models/user";
import { AuthService } from "@app/auth/services/auth.service";
import { AuthTokenService } from "@app/auth/services/auth-token.service";
import { SignUpForm } from "@models/sign_up_form";
import { first } from "rxjs/operators";
import { SignUpFormsService } from "@app/sign_up_forms/services/sign_up_forms.service";
import { FlowService } from "@app/shared/services/flow.service";
import { BroadcastMessage } from "@models/broadcast_message";
import { BroadcastService } from "@app/shared/services/broadcast.service";
import { Response } from "@models/response";
import { StoreNotification } from "@models/store_notification";
import { StoreNotificationsService } from "@app/shared/services/store_notifications.service";

@Component({
  selector: "sb-header",
  styleUrls: ["./sb-header.component.scss"],
  templateUrl: "./sb-header.component.html",
})
export class SbHeaderComponent {
  store: Store;
  user: User;
  storeLogo: string;
  isImpersonating: boolean;
  hasAuthToken: boolean;
  useDefaultStoreIcon: boolean;
  showDropdown: boolean = false;
  hidingDropdown: boolean;
  storeName = "";

  showNotificationsDropdown: boolean = false;
  hidingNotificationDropdown: boolean;
  notifications: StoreNotification[];
  popups: StoreNotification[];
  popupNotification: StoreNotification;
  popupLoaded = false;
  @ViewChild('popupNotificationContent', { static: true }) popupNotificationContent: TemplateRef<any>;
  notificationCheckId: any;
  newNotifications: boolean;
  newNotificationsCount: number;

  constructor(
    private router: Router,
    private userService: UserService,
    public modalService: NgbModal,
    private storeService: StoreService,
    private authService: AuthService,
    private authTokenService: AuthTokenService,
    private signUpFormsService: SignUpFormsService,
    private flowService: FlowService,
    private broadcastService: BroadcastService,
    private notificationService: StoreNotificationsService,
  ) {}

  ngOnInit() {
    this.user = this.userService.getSource().getValue();
    if (!this.user) {
      this.userService.show().subscribe((user) => {
        this.user = user;
      });
    }
    this.store = this.storeService.getSource().getValue();
    this.setStore(this.store);
    this.isImpersonating = this.authService.isImpersonating();
    this.hasAuthToken = !!this.authTokenService.getToken();
    this.getNotifications();
    this.notificationCheckId = setInterval(() => {
      this.getNotifications();
    }, 30000);

    this.broadcastService.listen("storeUpdated").subscribe((message: BroadcastMessage) => {
      this.setStore(message.payload.store);
    });

    this.broadcastService.listen("userUpdated").subscribe((message: BroadcastMessage) => {
      console.log("Received user update message")
      this.user = message.payload.user;
    });
  }

  ngOnDestroy() {
    if (this.notificationCheckId) {
      clearInterval(this.notificationCheckId);
    }
  }

  setStore(store: Store) {
    this.store = Object.assign(store);
    if (this.store) {
      let logoUrl = this.store?.branding?.logoUrl;
      if (!logoUrl || logoUrl === 'https://springbot-assets.s3.amazonaws.com/images/default_logo.png') {
        this.useDefaultStoreIcon = true;
      } else {
        this.storeLogo = `url('${this.store.branding.logoUrl}')`;
      }

      this.storeName = store.name.replace(/^https?:\/\//, '');
    } else {
      this.useDefaultStoreIcon = true;
    }
  }

  navigate(link): void {
    this.router.navigateByUrl(link);
  }

  openChangeStore(content: TemplateRef<any>): void {
    this.modalService.open(content);
  }

  stopImpersonating() {
    this.authService.stopImpersonating();
  }

  onChangeStore(storeId: number) {
    this.user.currentStoreId = storeId;
    this.userService.update(this.user).subscribe(() => {
      window.location.href = "/";
    });
  }

  logout(): void {
    this.authService.logout().subscribe((response) => {
      this.router.navigateByUrl('/login');
    });
  }

  toggleDropdown(show: boolean, timeout = 500): void {
    if (show) {
      this.hidingDropdown = false;
      this.showDropdown = true;
    } else {
      this.hidingDropdown = true;
      setTimeout(() => {
        if (this.hidingDropdown) {
          this.showDropdown = false;
        }
      }, timeout);
    }
  }

  toggleNotificationsDropdown(show: boolean, timeout = 500): void {
    if (show) {
      this.hidingNotificationDropdown = false;
      this.showNotificationsDropdown = true;
    } else {
      this.hidingNotificationDropdown = true;
      setTimeout(() => {
        if (this.hidingNotificationDropdown) {
          this.showNotificationsDropdown = false;
        }
      }, timeout);
    }
  }

  createForm() {
    this.signUpFormsService.create(
        new SignUpForm(), this.store,
    ).subscribe((signUpForm) => {
      this.router.navigateByUrl(`/forms/${signUpForm.id}/configure`);
    });
  }

  createFlow() {
    this.flowService.create().pipe(first()).subscribe((flow) => {
      this.router.navigateByUrl(`/automations/flows/${flow.id}/edit`);
    });
  }

  dismissPopupNotification(notification: StoreNotification, modal) {
    notification.dismissed = true;
    modal.close();
    this.notificationService.dismiss(notification.id).subscribe(() => {
      this.popupLoaded = false;
    });
  }

  clickNotification(notification) {
    this.notificationService.dismiss(notification.id).subscribe(() => {
      notification.dismissed = true;
      if (!notification.url) {
        return;
      } else if (notification.url === window.location.href) {
        window.open(notification.url);
      } else if (notification.url.indexOf("http") > -1) {
        window.open(notification.url, '_blank');
      } else {
        this.router.navigateByUrl(notification.url);
      }
    });
  }

  private getNotifications() {
    this.notificationService.index().subscribe((response: Response<StoreNotification[]>) => {
      this.notifications = response.data;
      let newNotificaitonsCount = 0;
      this.notifications.forEach((notification) => {
        if (!notification.dismissed) {
          this.newNotifications = true;
          newNotificaitonsCount++;
        }
      });
      this.newNotificationsCount = newNotificaitonsCount;
    });

    this.notificationService.index({ popups: true }).subscribe((response: Response<StoreNotification[]>) => {
      this.popups = response.data;
      if (this.popups.length > 0 && !this.popupLoaded) {
        this.popupLoaded = true;
        this.popupNotification = this.popups[0];
        const modalRef =  this.modalService.open(this.popupNotificationContent, {size: "lg"});
        modalRef.dismissed.subscribe((reason: any) => {
          this.notificationService.dismiss(this.popupNotification.id).subscribe(() => {
            this.popupNotification.dismissed = true;
            this.popupLoaded = false;
          });
        });
        this.popupLoaded = true;
      }
    });
  }
}
