<sb-breadcrumbs *ngIf="showBreadcrumbs" [links]="[
  { label: 'Performance', url: '/performance/dashboard' },
  { label: 'Channels' }
]"></sb-breadcrumbs>

<div class="bg-light" [class.container-fluid]="!embedded" [class.h-100]="embedded" [class.pt-4]="!embedded && showBreadcrumbs">
  <div class="d-flex justify-content-center" [class.h-100]="embedded" [class.row]="!embedded">
    <div class="card"
         id="sb-dashboard__channels_table"
         [class.mt-4]="!embedded"
         [class.h-100]="embedded"
         [class.col-12]="embedded"
         [class.col-11]="!embedded"
         [class.col-md-9]="!embedded">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <h5 class="blue-dark-color">Marketing Channels</h5>
          </div>
          <div class="col-12 col-md-6 text-right" *ngIf="embedded">
            <a routerLink="/performance/dashboard">
              View full analysis
              <span> <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon> </span>
            </a>
          </div>
          <div class="col-12 col-md-6" *ngIf="!filterOverride">
            <date-range (startDateChange)="filter.startDate = $event;" (endDateChange)="filter.endDate = $event; getChannels()"
                        [startDate]="filter.startDate"
                        [endDate]="filter.endDate" class="w-100"></date-range>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12 table-responsive mb-4" *ngIf="!loading">
            <table sortable (sort)="sortBy($event)" class="table table-sm table-striped">
              <thead class="bg-primary text-white">
                <tr class="text-center">
                  <th class="w-25" sortName="displayName">Channel</th>
                  <th sortName="revenue">Revenue</th>
                  <th sortName="actionsTaken" class="text-center">Actions</th>
                  <th sortName="clicks" class="text-center">Clicks</th>
                  <th sortName="orders" class="text-center">Orders</th>
                <ng-container *ngIf="!embedded">
                  <th sortName="ctc" class="text-center">CTC</th>
                  <th sortName="aoc" class="text-center">AOV</th>
                </ng-container>
                </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let channel of channels">
                <tr *ngIf="channel.stats && channel.name != 'Social'">
                  <td>
                    <a [href]="'/performance/channels/' + channel.id" *ngIf="channel.id">
                      <fa-icon [icon]="getIcon(channel)" class="mr-1"></fa-icon>
                      <span class="hide-on-mobile">{{ channel.displayName }}</span>
                    </a>
                    <a [href]="'/onsite_abandonment'" *ngIf="channel.name === 'Onsite Abandonment'">
                      <fa-icon [icon]="getIcon(channel)" class="mr-1"></fa-icon>
                      <span class="hide-on-mobile">{{ channel.displayName }}</span>
                    </a>
                  </td>
                  <td class="text-center">{{ channel.stats.revenue | currency:store.currencyCode:undefined:'1.0-2' }}</td>
                  <td class="text-center">
                    <span class="py-2" style="cursor: default;"
                          [ngbPopover]="actionPopoverTextContent"
                          #actionLabelPopover="ngbPopover"
                          placement="top"
                          (mouseenter)="actionPopoverText = channel.actionLabel;actionLabelPopover.open()"
                          (mouseleave)="actionLabelPopover.close()">
                      {{ channel.stats.actionsTaken | number }}
                    </span>
                  </td>
                  <td class="text-center">{{ channel.stats.clicks | number }}</td>
                  <td class="text-center">{{ channel.stats.orders | number }}</td>
                  <ng-container *ngIf="!embedded">
                  <td class="text-center">{{ (channel.stats.ctc / 100 ) | percent:'1.0-2' }}</td>
                  <td class="text-center">{{ channel.stats.aov | currency:store.currencyCode:undefined:'1.0-0' }}</td>
                  </ng-container>
                </tr>
              </ng-container>
              <tr class="bg-white" *ngIf="!channels">
                <td colspan="9" class="text-center">
                  <fa-icon [icon]="['fas', 'cog']" [spin]="true" class="green-color"></fa-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="loading">
          <div class="col">
              <div class="mx-auto w-50 p-3 text-center">
                <i class="fas fa-3x fa-cog fa-spin text-primary"></i>
              </div>
          </div>
        </div>
        <div class="row mt-2" [class.embedded-footer]="embedded">
          <div class="col-md-6 pt-2">
            <button (click)="getCsv()" class="btn btn-link">
              <fa-icon [icon]="['fas', 'download']"></fa-icon>
              Export as CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #actionPopoverTextContent>
  {{ actionPopoverText }}
</ng-template>
