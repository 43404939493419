import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import { ChartsService } from "@app/shared/services/charts.service";
import { formatDate } from "@angular/common";

import { StoreService } from "@app/shared/services/store.service";
import { ChartFilter } from "@models/automations/chart_filter";
import { Store } from "@models/store";
import { ChartDataset, ChartOptions, TimeUnit } from "chart.js";

@Component({
  selector: "subscribers-chart",
  styleUrls: ["./subscribers_chart.component.scss"],
  templateUrl: "./subscribers_chart.component.html",
})
export class SubscribersChartComponent implements OnInit, OnChanges {
  startDate: Date = new Date();
  endDate: Date = new Date();

  @Input()
  filterOverride: ChartFilter;

  @Output()
  startDateChange = new EventEmitter<any>();

  @Output()
  dateChanged = new EventEmitter<any>();

  data: ChartDataset[];
  store: Store;
  activeSubscribersCount: number;
  options: ChartOptions;
  chartUnit: TimeUnit = "day";
  loadingSubscriberGrowthGraph: boolean = true;
  filter: ChartFilter;

  constructor(
      private chartsService: ChartsService,
      private storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.store = this.storeService.getSource().value;
    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 90);
    this.filter = new ChartFilter({ startDate: this.startDate.toString(), endDate: this.endDate.toString() });
    this.getChartData();
  }

  ngOnChanges() {
    if (this.filter) {
      this.getChartData();
    }
  }

  getChartData() {
    this.loadingSubscriberGrowthGraph = true;
    this.filter.startDate = this.startDate.toISOString();
    this.filter.endDate = this.endDate.toISOString();
    this.dateChanged.emit(this.filter);
    this.setChartOptions();

    this.chartsService
      .getSubscribersData(this.filterOverride || this.filter)
      .subscribe((subscribersResponse: any) => {
        const data = Object.keys(subscribersResponse.data)
          .map((key, index, keys) => {
            const currentDate = new Date(key).getTime();
            const currentSubscribers = subscribersResponse.data[key];

            // Calculate the daily increase in subscribers
            let dailyIncrease = 0;
            if (index > 0) {
              const previousKey = keys[index - 1];
              const previousSubscribers = subscribersResponse.data[previousKey];
              dailyIncrease = currentSubscribers - previousSubscribers;
            }

            // Skip the first day since there's no previous data to compare
            return index > 0 ? { x: currentDate, y: dailyIncrease } : null;
          })
          .filter((dataPoint) => dataPoint !== null) // Remove the first day
          .sort((a, b) => (a.x < b.x ? -1 : 1));

        this.data = [
          {
            data,
            tension: 0,
            pointRadius: 3,
            borderColor: "green",
            backgroundColor: "rgb(194,229,199)",
            pointBackgroundColor: "green",
          },
        ];

        this.activeSubscribersCount = subscribersResponse.active_subscribers_count;
        this.loadingSubscriberGrowthGraph = false;
      });
  }

  setChartOptions() {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return formatDate(context[0].parsed.x, "MMM dd, yyyy", "en-US");
            },
            label: (tooltipItem) => {
              const value = tooltipItem.formattedValue;
              return `${Number(value)} new subscribers`
            }
          }
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          time: {
            unit: this.chartUnit,
          },
          type: "time",
        },
        y: {
          beginAtZero: true,
        },
      },
    };
  }
}
