<footer class="px-5 py-3 bg-gray-700 text-white" [ngClass]="{ 'fixed-bottom': fixedBottom }">
  <div class="row">
    <div class="col-sm-6 pl-4">
      <span class="small font-weight-bold">
        © {{ currentYear }} Springbot, Inc. All rights reserved.
      </span>
    </div>
    <div class="col-sm-6 text-right pr-4">
      <a class="mr-3 small text-white font-weight-bold" href="//www.springbot.com/terms-of-use/" target="_blank">
        Terms of Use
      </a>
      <a class="small text-white font-weight-bold" href="//www.springbot.com/privacy-policy/" target="_blank">
        Privacy Policy
      </a>
      <span class="ml-3 mr-3 small font-weight-bold" *ngIf="storeId">
        Store {{ storeId }}
      </span>
    </div>
  </div>
</footer>
