<div class="d-flex flex-column justify-content-between align-items-center">
  <div class="text-center w-100 border-bottom border-muted" *ngIf="!hideControls">
    <button class="btn btn-link" [class.text-muted]="mobile" (click)="mobile = false">
      Desktop
    </button>
    <button class="btn btn-link" [class.text-muted]="!mobile" (click)="mobile = true">
      Mobile
    </button>
  </div>
  <div class="overflow-auto w-100 h-100" *ngIf="mobile; then renderMobile else renderDesktop"></div>
</div>

<ng-template #renderDesktop>
  <iframe-preview-desktop [content]="content" class="w-100"></iframe-preview-desktop>
</ng-template>

<ng-template #renderMobile>
  <iframe-preview-mobile [content]="content"></iframe-preview-mobile>
</ng-template>
