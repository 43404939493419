<div *ngIf="recentEmailCampaignsCount >= 1" class="container">
  <div class="row">
    <email-campaigns-template-card
        cardName="Recently sent"
        [campaign]="mostRecent"
        [data]="mostRecentData">
    </email-campaigns-template-card>
    <email-campaigns-template-card
        cardName="Best subject line"
        [campaign]="topOpens"
        [data]="topOpensData"
        info="Your email that had the highest open rate over the last 30 days">
    </email-campaigns-template-card>
    <email-campaigns-template-card
        cardName="Most clicks"
        [campaign]="topClicks"
        [data]="topClicksData"
        info="Your email that had the highest click rate over the last 30 days">
    </email-campaigns-template-card>
    <email-campaigns-template-card
        cardName="Highest revenue"
        [campaign]="topRevenue"
        [data]="topRevenueData"
        info="Your email that made the most revenue in the last 30 days">
    </email-campaigns-template-card>
  </div>
</div>
