export class EmailCampaignsFilter {
  name: string;
  page = 1;
  perPage = 10;
  state: string;
  sort: string;
  withMultivariateTests: boolean;
  hasContent = false;
  filterStartDate?: Date;
  filterEndDate?: Date;

  constructor(params?: Partial<EmailCampaignsFilter>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
