import { Component, ElementRef, Input, ViewChild } from "@angular/core";

@Component({
  selector: "iframe-preview-mobile",
  styleUrls: ["./iframe_preview_mobile.component.scss"],
  templateUrl: "./iframe_preview_mobile.component.html",
})
export class IframePreviewMobileComponent {
  @Input()
  content: string;

  @ViewChild("iframe", { static: true })
  iframe: ElementRef<HTMLIFrameElement>;

  ngOnInit(): void {
    this.iframe.nativeElement.contentWindow.document.write(this.content);
  }
}
