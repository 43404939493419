import {
  EditorEmailModel,
  EditorEmailModelInterface,
} from "@app/content_editor/models/editor_email_model";
import { EmailTemplate } from "@models/email_templates/email_template";
import { Node } from "@models/automations/node";
import { SubTemplate } from "@models/email_templates/sub_template";

export class AutomationsAction implements EditorEmailModelInterface {
  id: number;
  flowId: number;

  blacklistedDomains?: any;
  customerNameOther?: string;
  defaultCustomerName?: string;
  demoEmails?: string;
  enablePersonalizedProductRecommendations: boolean;
  fromEmail?: string;
  fromName?: string;
  identifier?: string;
  mjmlContent?: string;
  name: string;
  node: Node;
  preheader?: string;
  senderEmail?: string;
  senderName?: string;
  subject?: string;
  subjectOther?: string;
  timeTilSend?: number;
  uniqueCouponCampaignId?: string;
  couponCampaignId?: string;
  templateName?: string;
  keywordId?: number;
  content?: string;
  senderEmailValid?: boolean;
  attributes?: any;
  contentSourceId?: string;
  contentSource?: string;
  template?: EmailTemplate;
  thumbnailUrl?: string;
  useStoreTemplate: boolean;
  imageUrl?: string;
  cartTemplate: SubTemplate = new SubTemplate();
  purchasedCartTemplate: SubTemplate = new SubTemplate();
  productRecommendationsTemplate: SubTemplate = new SubTemplate();
  triggerType: "triggered_email"; // This is here until we add types for them
  showCartTemplate?: boolean;
  showPurchasedCartTemplate?: boolean;
  body?: string;

  constructor(params?: Partial<AutomationsAction>) {
    if (params) {
      const tempParams = { ...params };
      tempParams.cartTemplate = new SubTemplate(tempParams.cartTemplate);
      tempParams.purchasedCartTemplate = new SubTemplate(
        tempParams.purchasedCartTemplate
      );
      tempParams.productRecommendationsTemplate = new SubTemplate(
        tempParams.productRecommendationsTemplate
      );
      tempParams.triggerType = "triggered_email";
      Object.assign(this, tempParams);
    }
  }

  // Right now we are just saying yes to all automations emails
  // If we want to limit this later this would change.
  // AngularJS cart.js requires this to be a function.
  canUseCarts(): boolean {
    return true;
  }

  canUseRecoModule(): boolean {
    return true;
  }

  // These functions are here until we port the cart, purchased_cart, and
  // product_recommendation modules to Angular
  // TODO: remove these when we port to Angular
  get(key: string): any {
    return this[this.snakeToCamel(key)];
  }

  set(key: string, value: any): any {
    this[this.snakeToCamel(key)] = value;
  }

  // Because the editor is upgraded from angular.js and uses snake_case everywhere.
  snakeToCamel(str: string): string {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
  }
}
