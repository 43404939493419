<div class="text-center">
  <h2 class="subheader blue-dark-color nowrap">
    {{ cardName }}
    <span *ngIf="!!info" [ngbTooltip]="info">
      <fa-icon [icon]="['fas', 'info-circle']" class="info-icon"></fa-icon>
    </span>
  </h2>

  <div class="card">
    <div class="set-commands overlay w-100 h-100">
      <div class="item-command cursor-pointer w-100 text-center" (click)="onPreview(previewCampaignContent)">
        <fa-icon [icon]="['fas', 'eye']"></fa-icon>
        <p class="pl-2">Preview email</p>
      </div>
      <div class="item-command cursor-pointer w-100 text-center" (click)="onDuplicate()">
        <fa-icon [icon]="['fas', 'copy']"></fa-icon>
        <p class="pl-2">Duplicate email</p>
      </div>
      <div class="item-command cursor-pointer w-100 text-center" (click)="onReview()">
        <fa-icon [icon]="['fas', 'clipboard-list']"></fa-icon>
        <p class="pl-2">Review details</p>
      </div>
      <div class="item-command cursor-pointer w-100 text-center" (click)="onFullAnalysis()">
        <fa-icon [icon]="['fas', 'chart-line']"></fa-icon>
        <p class="pl-2">View full analysis</p>
      </div>
    </div>

    <div class="card-body text-center campaign-name">
      <span class="font-weight-bold nowrap" [title]="data?.name || campaign?.name">
        {{ data?.name || campaign?.name }}
      </span>
    </div>
    <div class="card-footer campaign-details">
      <div class="row">
        <span class="col-6 text-left font-weight-bold nowrap">Open</span>
        <span class="col-6 text-right">{{ data?.open | number }}</span>
      </div>
      <div class="row">
        <span class="col-6 text-left font-weight-bold nowrap">Clicks</span>
        <span class="col-6 text-right">{{ data?.clicks | number }}</span>
      </div>
      <div class="row">
        <span class="col-6 text-left font-weight-bold nowrap">Revenue</span>
        <span class="col-6 text-right">{{ data?.revenue | currency }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Preview modal -->
<ng-template #previewCampaignContent let-modal>
  <button
    type="button"
    class="position-absolute close-top close px-2 py-1"
    aria-label="Close"
    style="left: 95%"
    (click)="modal.dismiss()">
    <span class="text-dark" aria-hidden="true">&times;</span>
  </button>
  <email-preview [content]="emailCampaignPreviewContent"></email-preview>
</ng-template>
