import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "email-preview",
  styleUrls: ["./email_preview.component.scss"],
  templateUrl: "./email_preview.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class EmailPreviewComponent {
  @Input()
  content: string;

  @Input()
  hideControls: boolean = false;

  @Input()
  mobile: boolean;

  ngOnInit(): void {
    this.retargetAllContentLinks();
  }

  retargetAllContentLinks(): void {
    const sandboxDiv: HTMLDivElement = document.createElement("div");
    sandboxDiv.innerHTML = this.content;
    sandboxDiv
      .querySelectorAll<HTMLLinkElement>("a")
      .forEach((link: HTMLLinkElement) => {
        link.target = "_blank";
      });
    this.content = sandboxDiv.innerHTML;
  }
}
