import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MultivariateTest } from "@models/multivariate_test";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { EmailCampaign } from "@models/email_campaign";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class EmailCampaignsService {
  private campaignSource$ = new BehaviorSubject<EmailCampaign>(null);

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getCampaign(): BehaviorSubject<EmailCampaign> {
    return this.campaignSource$;
  }

  setCampaign(campaign: EmailCampaign): void {
    this.campaignSource$.next(campaign);
  }

  adminDuplicate(emailCampaignId: string, storeId: number) {
    return this.httpClient.post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/admin_duplicate`, { storeId });
  }

  adminSendToUndelivered(emailCampaignId: string) {
    return this.httpClient.post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/admin_send_to_undelivered`, {});
  }

  index(params?: any): Observable<Response<EmailCampaign[]>> {
    return this.httpClient.get<any>(`${environment.apiUrl}/v2/email_campaigns`, { params }).pipe(
      mergeMap(async (emailCampaignsResponse) => {
        const emailCampaigns = this.deserializeService.deserialize(emailCampaignsResponse);

        /* eslint-disable */
        for (const campaign of emailCampaigns) {
          if (campaign.variants) {
            const winner = this.deserializeService.deserialize({ data: campaign.winner });
            campaign.winner = winner;

            const variants = this.deserializeService.deserialize({ data: campaign.variants });
            campaign.variants = this.sortList(variants, "createdAt");
          }
        }
        /* eslint-enable */

        return new Response(emailCampaigns, emailCampaignsResponse.meta);
      }),
    );
  }

  destroy(emailCampaignId: string) {
    return this.httpClient.delete<EmailCampaign>(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}`, {}).pipe(
      mergeMap(async (emailCampaignsResponse) => this.deserializeService.deserialize(emailCampaignsResponse)),
    );
  }

  duplicate(emailCampaignId: string, create_variant = false) {
    return this.httpClient.post<EmailCampaign>(
      `${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/duplicate?create_variant=${create_variant}`, {},
    ).pipe(mergeMap(async (emailCampaignsResponse) => this.deserializeService.deserialize(emailCampaignsResponse)));
  }

  show(emailCampaignId: string, params?: any) {
    return this.httpClient.get<EmailCampaign>(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}`, { params }).pipe(
      mergeMap(async (emailCampaignsResponse) => {
        emailCampaignsResponse = this.deserializeCustomAttributes(emailCampaignsResponse);

        return this.deserializeService.deserialize(emailCampaignsResponse);
      }),
    );
  }

  engage(emailCampaignId: string, type: "click" | "open", engaged: boolean) {
    return this.httpClient.post<EmailCampaign>(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/engage`, {
      type, engaged,
    }).pipe(mergeMap(async (emailCampaignsResponse) => this.deserializeService.deserialize(emailCampaignsResponse)));
  }

  preview(emailCampaignId: string) {
    return this.httpClient.get<{ preview: string }>(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/preview`);
  }

  sendPreviewEmails(emailCampaignId: string, emails: string) {
    return this.httpClient.post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/send_preview`, { data: { emails } });
  }

  schedule(emailCampaignId: string, params: any) {
    return this.httpClient.post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/schedule`, { data: params });
  }

  sendNow(emailCampaignId: string) {
    return this.httpClient.post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/send_now`, { data: {} });
  }

  cancel(emailCampaignId: string) {
    return this.httpClient.post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/cancel`, { data: {} });
  }

  update(emailCampaign: EmailCampaign): Observable<EmailCampaign> {
    return this.httpClient.put<any>(`${environment.apiUrl}/v2/email_campaigns/${emailCampaign.id}`, emailCampaign).pipe(
      mergeMap(async (emailCampaignsResponse) => {
        emailCampaignsResponse = this.deserializeCustomAttributes(emailCampaignsResponse);

        return this.deserializeService.deserialize(emailCampaignsResponse);
      }),
    );
  }

  create(emailCampaign: EmailCampaign): Observable<EmailCampaign> {
    return this.httpClient.post<EmailCampaign>(`${environment.apiUrl}/v2/email_campaigns/`, emailCampaign).pipe(
      mergeMap(async (emailCampaignsResponse) => {
        emailCampaignsResponse = this.deserializeCustomAttributes(emailCampaignsResponse);

        return this.deserializeService.deserialize(emailCampaignsResponse);
      }),
    );
  }

  convertToMultivariate(campaign: EmailCampaign): Observable<MultivariateTest> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v2/email_campaigns/${campaign.id}/convert_to_multivariate_test`, campaign).pipe(
      mergeMap(async (multivariateTestResponse) => {
        const winner = this.deserializeService.deserialize({ data: multivariateTestResponse.data.attributes.winner });
        multivariateTestResponse.data.attributes.winner = winner;

        const variants = this.deserializeService.deserialize({ data: multivariateTestResponse.data.attributes.variants });
        multivariateTestResponse.data.attributes.variants = this.sortList(variants, "createdAt");

        const response = this.deserializeService.deserialize(multivariateTestResponse);

        return response;
      }),
    );
  }

  exportEngagedRecipients(emailCampaignId: string, recipientEmail: string) {
    return this.httpClient
      .post(`${environment.apiUrl}/v2/email_campaigns/${emailCampaignId}/export_engaged_recipients`, { recipientEmail });
  }

  storeDuration(storeId: string, params: any) {
    const duration = this.httpClient
      .get(`${environment.apiUrl}/v2/email_campaigns/${storeId}/duration`, { params });
    return duration;
  }

  getStatistics(): Observable<any> {this.httpClient
      return this.httpClient.get(`${environment.apiUrl}/v2/email_campaigns/statistics`);
  }

  getBasicTemplate(template: string): Observable<string> {
    return this.httpClient.get(`https://springbot-assets.s3.amazonaws.com/mjml_templates_with_mergetags/${template}.mjml`,{ responseType: 'text' });
  }

  getBasicTemplates() {
    return [
      {
        "name": "1 Column",
        "thumbnailUrl": "https://springbot_www_production.s3.amazonaws.com/springmail/4071/8ecae8fa508b662609e7524e7809fa86012cb05d20e064bada4f342bfd68aec8.png",
        "templateUrl": "one_column",
        "position": 1
      },
      {
        "name": "1:2 Column",
        "thumbnailUrl": "https://s3.amazonaws.com/springbot/basic_templates/one_two.png",
        "templateUrl": "one_two_column",
        "position": 2
      },
      {
        "name": "2:1 Column",
        "thumbnailUrl": "https://springbot_www_production.s3.amazonaws.com/springmail/4071/431050c610fb1bd59fff560914f4cd63baed5fd8661ab8de6825a4aea2066940.png",
        "templateUrl": "two_one_column",
        "position": 3
      },
      {
        "name": "Alternating Images (Right, Left, Right)",
        "thumbnailUrl": "https://springbot_www_production.s3.amazonaws.com/springmail/12251/eb630331f580d1e6b6ed188b318f48771b57d4579fd6640163f03f9f8a78352e.png",
        "templateUrl": "alternating_images_right_left_right",
        "position": 4
      },
      {
        "name": "Alternating Images (Left, Right, Left)",
        "thumbnailUrl": "https://s3.amazonaws.com/springbot/basic_templates/alternating_images.png",
        "templateUrl": "alternating_images_left_right_left",
        "position": 5
      },
      {
        "name": "Onsite Abandonment",
        "thumbnailUrl": "https://s3.amazonaws.com/springbot/basic_templates/one_column.png",
        "templateUrl": "onsite_abandonment",
        "position": 6,
      },
      {
        "name": "1:3 Column",
        "thumbnailUrl": "https://s3.amazonaws.com/springbot/basic_templates/one_three.png",
        "templateUrl": "one_three_column",
        "position": 7,
      },
      {
        "name": "Right Sidebar",
        "thumbnailUrl": "https://s3.amazonaws.com/springbot/basic_templates/right_sidebar.png",
        "templateUrl": "right_sidebar",
        "position": 8,
      },
      {
        "name": "1:2:1 Column",
        "thumbnailUrl": "https://springbot_www_production.s3.amazonaws.com/springmail/4071/1aa5feae8fd3ee0c0a83a3879aef9de8b0e7d0020737d54150ccde039bdaca46.png",
        "templateUrl": "one_two_one_column",
        "position": 9,
      },
      {
        "name": "Left Sidebar",
        "thumbnailUrl": "https://springbot_www_production.s3.amazonaws.com/springmail/4071/a0fcf87084409846c4038084eca631044c81a4e3d5c2f090f99e19ca8c3d6a32.png",
        "templateUrl": "left_sidebar",
        "position": 11,
      },
      {
        "name": "Blank",
        "thumbnailUrl": "https://s3.amazonaws.com/springbot/basic_templates/blank_slate.png",
        "templateUrl": "blank",
        "position": 12,
      }
    ];
  }

  private deserializeCustomAttributes(emailCampaignsResponse): any {
    emailCampaignsResponse.data.attributes.includedListIds = (
      emailCampaignsResponse.data.attributes.includedListIds.map((list) => list.$oid)
    );
    emailCampaignsResponse.data.attributes.includedFilterIds = (
      emailCampaignsResponse.data.attributes.includedFilterIds.map((list) => list.$oid)
    );
    emailCampaignsResponse.data.attributes.excludedListIds = (
      emailCampaignsResponse.data.attributes.excludedListIds.map((list) => list.$oid)
    );
    emailCampaignsResponse.data.attributes.excludedFilterIds = (
      emailCampaignsResponse.data.attributes.excludedFilterIds.map((list) => list.$oid)
    );
    return emailCampaignsResponse;
  }

  private sortList(list: any, field: string, ascending = true) {
    if (ascending) {
      return list.sort((a, b) => parseFloat(a[field]) - parseFloat(b[field]));
    }
    return list.sort((a, b) => parseFloat(b[field]) - parseFloat(a[field]));
  }
}
