import { Component, Input, OnInit } from "@angular/core";
import { NavLink } from "@models/navlink";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@models/store";

@Component({
  selector: "sb-nav",
  styleUrls: ["./sb-nav.component.scss"],
  templateUrl: "./sb-nav.component.html",
})
export class SbNavComponent implements OnInit {
  @Input()
  store: Store;

  @Input()
  links: NavLink[];

  @Input()
  onlyEmailLists: boolean;

  @Input()
  showCreateButton: boolean = false;

  hover: string;
  hidingMenu: boolean;
  showNavbar: boolean = false;

  constructor(
    private router: Router,
    public modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.showNavbar = this.router.url !== "/registration_complete";
  }

  linkActive(link: NavLink): boolean {
    if (this.router.url === link.url) { return true; }

    if (!link.children) { return false; }

    let childLinkActive = false;
     link.children.forEach((child: NavLink) => {
      if (this.router.url.includes(child.url)) { childLinkActive = true; }
    });

    return childLinkActive;
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  clickEvent(link: NavLink): void {
    if (!link.url) {
      this.hover = link.label;
      setTimeout(() => {
        this.hover = null;
      }, 5000);
    } else if (this.hover && this.hover == link.label) {
      this.hover = null;
    } else if (!link.url || link.disabled) {
      return;
    } else if (link.external) {
      this.hover = null;
    } else {
      this.hover = null;
      this.router.navigateByUrl(link.url);
    }
  }

  mouseEnterEvent(link: NavLink): void {
    this.hidingMenu = false;
    if (link.children && link.children.length > 0 && !link.disabled) {
      this.hover = link.label;
    } else {
      this.hover = null;
    }
  }

  mouseLeaveEvent(): void {
    this.hidingMenu = true;
    setTimeout(() => {
      if (this.hidingMenu) {
        this.hover = null;
      }
    }, 500);
  }
}
