import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Statistic } from "@models/statistic";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(private httpClient: HttpClient) {}

  change(
    source: "store" | "tag" | "action" | "product",
    params?: any
  ): Observable<Statistic> {
    return this.httpClient.get<Statistic>(`${environment.apiUrl}/v2/${source}_query_values/change`, {
      params,
    });
  }

  graph(
    source: "store" | "tag" | "action" | "product",
    params?: any
  ): Observable<Statistic> {
    return this.httpClient.get<Statistic>(`${environment.apiUrl}/v2/${source}_query_values/graph`, {
      params,
    });
  }

  percentage(
    source: "store" | "tag" | "action" | "product",
    params?: any
  ): Observable<Statistic> {
    return this.httpClient.get<Statistic>(
      `${environment.apiUrl}/v2/${source}_query_values/percentage`,
      { params }
    );
  }

  value(
    source: "store" | "tag" | "action" | "product",
    params?: any
  ): Observable<Statistic> {
    return this.httpClient.get<Statistic>(`${environment.apiUrl}/v2/${source}_query_values/value`, {
      params,
    });
  }

  stats(source: "store" | "tag" | "action" | "product", params?: any) {
    return this.httpClient.get<any>(`${environment.apiUrl}/v2/${source}_query_values/stats`, {
      params,
    });
  }
}
