import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { EmailCampaignsService } from '@app/email_campaigns/services/email_campaigns.service';
import { EmailCampaign } from "@models/email_campaign";
import { ITemplateData } from "@app/shared/components/template-card/template-card.component";
import { EmailCampaignsFilter } from "@models/email_campaigns_filter";
import * as moment from "moment";
import { ChartFilter } from "@models/automations/chart_filter";

@Component({
  selector: 'email-campaign-accolades',
  templateUrl: './email-campaign-accolades.component.html',
  styleUrls: ['./email-campaign-accolades.component.scss']
})
export class EmailCampaignAccoladesComponent implements OnInit, OnChanges {
  @Input()
  filterOverride: ChartFilter;

  mostRecent: EmailCampaign = undefined;
  mostRecentData: ITemplateData = undefined;
  topOpens: EmailCampaign = undefined;
  topOpensData: ITemplateData = undefined;
  topClicks: EmailCampaign = undefined;
  topClicksData: ITemplateData = undefined;
  topRevenue: EmailCampaign = undefined;
  topRevenueData: ITemplateData = undefined;
  recentEmailCampaignsCount: number;

  constructor(private emailCampaignsService: EmailCampaignsService) { }

  ngOnInit() {
    this.getTemplateCardCampaigns();
  }

  ngOnChanges() {
    this.getTemplateCardCampaigns();
  }

  getTemplateCardCampaigns() {
    let recentCampaigns: EmailCampaign[];
    let startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    let endDate =  new Date();
    if (this.filterOverride) {
      startDate = new Date(this.filterOverride.startDate);
      endDate = new Date(this.filterOverride.endDate);
    }
    let filter = new EmailCampaignsFilter({ sort: "created_at desc",
      withMultivariateTests: true,
      filterStartDate: startDate,
      filterEndDate: endDate,
      state: "sent" });
    this.emailCampaignsService.index(filter).subscribe((campaigns) => {
      recentCampaigns = campaigns.data;
      this.recentEmailCampaignsCount = campaigns.meta.total;

      // Init top campaigns
      let mostRecentName: string;
      let mostRecentTimeSent = moment('0001-01-01');
      let mostRecentCampaign: EmailCampaign = undefined;
      let highestOpenName: string;
      let highestOpenRate: number = -1.0;
      let highestOpenCampaign: EmailCampaign = undefined;
      let highestClickName: string;
      let highestClickRate: number = -1.0;
      let highestClickCampaign: EmailCampaign = undefined;
      let highestRevenueName: string;
      let highestRevenue: number = -99999999.0;
      let highestRevenueCampaign: EmailCampaign = undefined;

      // Process all campaigns to determine top campaigns
      recentCampaigns.forEach((campaign) => {
        let currCampaign = campaign;
        let currName = campaign.name;
        if (!!currCampaign.variants) {
          currCampaign.variants.forEach((variant) => {
            if (variant.isWinningVariant) {
              currCampaign = variant;
            }
          });
        }

        // Most recent
        if (mostRecentTimeSent.isBefore(currCampaign.sentAt)) {
          mostRecentTimeSent = moment(currCampaign.sentAt);
          mostRecentCampaign = currCampaign;
          mostRecentName = currName;
        }

        // Top open rate
        if (currCampaign.delivered.value) {
          let openRate = currCampaign.uniqueOpens.percentage;
          if (openRate > highestOpenRate) {
            highestOpenRate = openRate;
            highestOpenCampaign = currCampaign;
            highestOpenName = currName;
          }
        }

        // Top click rate
        if (currCampaign.delivered.value) {
          let clickRate = currCampaign.uniqueClicks.percentage;
          if (clickRate > highestClickRate) {
            highestClickRate = clickRate;
            highestClickCampaign = currCampaign;
            highestClickName = currName;
          }
        }

        // Top revenue
        let revenue = currCampaign.revenue;
        if (revenue > highestRevenue) {
          highestRevenue = revenue;
          highestRevenueCampaign = currCampaign;
          highestRevenueName = currName;
        }
      });

      // Set and config top campaigns data
      this.mostRecent = mostRecentCampaign;
      this.mostRecentData = this.setTemplateCard(this.mostRecent, mostRecentName);
      this.topOpens = highestOpenCampaign || mostRecentCampaign;
      this.topOpensData = this.setTemplateCard(this.topOpens, highestOpenName);
      this.topClicks = highestClickCampaign || mostRecentCampaign;
      this.topClicksData = this.setTemplateCard(this.topClicks, highestClickName);
      this.topRevenue = highestRevenueCampaign || mostRecentCampaign;
      this.topRevenueData = this.setTemplateCard(this.topRevenue, highestRevenueName)
    });
  }

  setTemplateCard(campaign: EmailCampaign, campaignName: string): ITemplateData {
    if (!!campaign) {
      return {
        name: campaignName,
        open: campaign.uniqueOpens.value,
        clicks: campaign.uniqueClicks.value,
        revenue: campaign.revenue
      };
    } else {
      return undefined;
    }
  }
}
