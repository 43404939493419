import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import Chart from 'chart.js/auto';  // Import Chart.js
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import chartjs-plugin-datalabels

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-campaign-industry-average-bar-chart',
  templateUrl: './campaign-industry-average-bar-chart.component.html',
  styleUrls: ['./campaign-industry-average-bar-chart.component.scss']
})
export class CampaignIndustryAverageBarChartComponent implements OnInit, OnChanges {
  @Input() storeData: number[] = [0, 0, 0, 0];
  @Input() barChartLabels: string[] = ['Opens', 'Clicks', 'Bounces', 'Unsubscribes'];
  @Input() tooltipLabels: string[] = ['Opens', 'Clicks', 'Bounces', 'Unsubscribes'];

  public barChartOptions: ChartOptions;
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;

  public barChartDataOpensClicks: ChartData<'bar'>;
  public barChartDataBouncesUnsubscribes: ChartData<'bar'>;

  constructor() {}

  ngOnInit(): void {
    this.renderChart();
  }

  ngOnChanges(): void {
    this.renderChart();
  }

  renderChart(): void {
    // Define a scaling factor multiplier (we want the smallest value to be at least 10% of the total height)
    const scaleFactor = 10;  // This ensures that values smaller than 10% take up at least 10% of the chart height

    // Calculate the maximum value in the data
    const allData = [
      ...this.storeData,
      15, 2.3, 0.25, 0.2  // Example values for Industry Average
    ];
    const maxValue = Math.max(...allData);

    // Scaling logic for each pair: Ensure the smallest value is scaled to take up at least 10% of the height
    const scaledStoreData = this.storeData.map((value, index) => {
      const industryValue = [15, 2.3, 0.25, 0.2][index];  // Corresponding Industry Average values
      const minValue = Math.min(value, industryValue);  // Get the smaller value in the pair

      // If the value is less than 10%, scale it up to take at least 10% of the chart height
      const scalingFactor = minValue < 10 ? scaleFactor : 1;  // Scale up only if the value is < 10%

      // Scale both store and industry values by the same factor
      return value * scalingFactor;
    });

    const scaledIndustryData = [15, 2.3, 0.25, 0.2].map((value, index) => {
      const storeValue = this.storeData[index];  // Corresponding Your Store values
      const minValue = Math.min(value, storeValue);  // Get the smaller value in the pair

      // If the value is less than 10%, scale it up to take at least 10% of the chart height
      const scalingFactor = minValue < 10 ? scaleFactor : 1;  // Scale up only if the value is < 10%

      // Scale both store and industry values by the same factor
      return value * scalingFactor;
    });

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      scales: {
        x: {
          title: { display: false },
          ticks: { display: false },
          grid: { display: false },
        },
        y: {
          title: { display: false },
          ticks: { display: false },
          grid: { display: false },
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const datasetLabel = tooltipItem.dataset.label;
              const value = tooltipItem.raw;

              if (datasetLabel === 'Your Store') {
                return `Your Store: ${Number(value).toFixed(2)}%`;
              } else if (datasetLabel === 'Industry Average') {
                return `Industry Average: ${Number(value).toFixed(2)}%`;
              } else {
                return `${datasetLabel}: ${Number(value).toFixed(2)}%`;
              }
            }
          }
        },
        datalabels: {
          display: true,  // Ensure data labels are visible
          color: '#ooo',  // Set text color for data labels
          font: {
            weight: 'bold',
            size: 16,  // Set font size for data labels
          },
          anchor: 'end',  // Anchor the labels at the end of the bars
          formatter: (value) => {
            let rounded = value;
            if (value > 5) {
              rounded = Math.round(value)
            } else {
              rounded = Math.round(value * 100)/100
            }
            return `${rounded}%`;
          }
        }
      },
      elements: {
        bar: {
          borderWidth: 1,
          borderColor: '#fff',
        }
      }
    };

    // Chart data for 'Opens' and 'Clicks' (first chart)
    this.barChartDataOpensClicks = {
      labels: [this.tooltipLabels[0], this.tooltipLabels[1]],  // 'Opens' and 'Clicks'
      datasets: [
        {
          label: 'Industry Average',
          data: [scaledIndustryData[0], scaledIndustryData[1]],  // Scaled values for 'Opens' and 'Clicks'
          backgroundColor: '#0092db',
          datalabels: {
            anchor: 'start',
            color: '#000',
            font: { weight: 'bold', size: 16 },
            borderRadius: 5,
            opacity: 0.7,
            display: true,
            align: 'end'
          }
        },
        {
          label: 'Your Store',
          data: [scaledStoreData[0], scaledStoreData[1]],  // Scaled values for 'Opens' and 'Clicks'
          backgroundColor: '#3EB049',
          datalabels: {
            anchor: 'start',
            color: '#000',
            font: { weight: 'bold', size: 16 },
            borderRadius: 5,
            opacity: 0.7,
            display: true,
            align: 'end'
          }
        }
      ]
    };

    // Chart data for 'Bounces' and 'Unsubscribes' (second chart)
    this.barChartDataBouncesUnsubscribes = {
      labels: [this.tooltipLabels[2], this.tooltipLabels[3]],  // 'Bounces' and 'Unsubscribes'
      datasets: [
        {
          label: 'Industry Average',
          data: [scaledIndustryData[2], scaledIndustryData[3]],  // Scaled values for 'Bounces' and 'Unsubscribes'
          backgroundColor: '#0092db',
          datalabels: {
            anchor: 'start',
            color: '#000',
            font: { weight: 'bold', size: 16 },
            borderRadius: 5,
            opacity: 0.7,
            display: true,
            align: 'end'
          }
        },
        {
          label: 'Your Store',
          data: [scaledStoreData[2], scaledStoreData[3]],  // Scaled values for 'Bounces' and 'Unsubscribes'
          backgroundColor: '#3EB049',
          datalabels: {
            anchor: 'start',
            color: '#000',
            font: { weight: 'bold', size: 16 },
            borderRadius: 5,
            opacity: 0.7,
            display: true,
            align: 'end'
          }
        }
      ]
    };

    // Create the first chart for 'Opens' and 'Clicks'
    const chartIdOpensClicks = 'myChartOpensClicks';
    const existingChartOpensClicks = Chart.getChart(chartIdOpensClicks);  // Check if the chart already exists
    if (existingChartOpensClicks) {
      existingChartOpensClicks.destroy();  // Destroy the old chart instance
    }
    new Chart(chartIdOpensClicks, {
      type: this.barChartType,
      data: this.barChartDataOpensClicks,
      options: this.barChartOptions
    });

    // Create the second chart for 'Bounces' and 'Unsubscribes'
    const chartIdBouncesUnsubscribes = 'myChartBouncesUnsubscribes';
    const existingChartBouncesUnsubscribes = Chart.getChart(chartIdBouncesUnsubscribes);  // Check if the chart already exists
    if (existingChartBouncesUnsubscribes) {
      existingChartBouncesUnsubscribes.destroy();  // Destroy the old chart instance
    }
    new Chart(chartIdBouncesUnsubscribes, {
      type: this.barChartType,
      data: this.barChartDataBouncesUnsubscribes,
      options: this.barChartOptions
    });
  }
}
