import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ObjectFormatter as formatter } from "@app/shared/tools/object_formatter";
import { Observable } from "rxjs";

@Injectable()
export class KeyFormatInterceptor implements HttpInterceptor {
  // if you have an endpoint that needs keys processed to snake_case, add url below
  // this keeps us from having to run serializers on all the outgoing objects
  interceptedUrls: string[] = [
    "v2/store",
    "v2/user",
    "v2/social_hub",
    "v2/social_hub/social_feeds",
    "v2/store_branding",
    "v2/springbot_providers",
    "v2/social_hub/reminders",
    "v2/onboarding_checklist",
    "v2/onsite_abandonment/emails",
    "v2/audience_expansion/runs",
  ];

  // urls where GET requests will also be intercepted
  // this is temporary until we handle the interceptor unification ticket
  getInterceptedUrls: string[] = [
    "v2/social_hub/social_blasts",
    "v2/social_hub/social_feeds",
    "v2/social_hub/twitter_posts",
    "v2/social_hub/twitter_favorites",
    "v2/social_hub/facebook_posts",
    "v2/social_hub/facebook_likes",
    "v2/social_hub/facebook_comments",
    "v2/social_hub/instagram_comments",
    "v2/social_hub/instagram_posts",
    "v2/onsite_abandonment/emails",
    "v2/audience_expansion/runs",
  ];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.method === "GET" &&
      this.getUrlInterceptable(req.url) &&
      req.params
    ) {
      return next.handle(this.formattedGetRequest(req));
    }
    if (this.urlInterceptable(req.url) && this.formattingNeeded(req.body)) {
      return next.handle(this.formattedRequest(req));
    }
    return next.handle(req);
  }

  private urlInterceptable(url: string): boolean {
    return !!this.interceptedUrls.find((baseUrl) => url.includes(baseUrl));
  }

  private getUrlInterceptable(url: string): boolean {
    return !!this.getInterceptedUrls.find((baseUrl) => url.includes(baseUrl));
  }

  private formattingNeeded(
    body: Record<string, any> | undefined | null
  ): boolean {
    return !!body && formatter.isKeyedObject(body);
  }

  private formattedRequest(req: HttpRequest<any>): HttpRequest<any> {
    const formattedBody = formatter.formatAllKeys({ ...req.body });
    return req.clone({ body: formattedBody });
  }

  // this may need improvements
  private formattedGetRequest(req: HttpRequest<any>): HttpRequest<any> {
    const params = req.params.keys().reduce((result, key) => {
      result[key] = req.params.get(key);
      return result;
    }, {});
    const formattedParams = formatter.formatAllKeys(params);
    return req.clone({
      params: new HttpParams({ fromObject: formattedParams }),
    });
  }
}
