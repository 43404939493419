<sb-breadcrumbs [links]="[{ label: 'Springbot Dashboard' }]"></sb-breadcrumbs>

<!-- Dashboard -->
<div class="fill-page homepage-background" *ngIf="!loading">
  <div *ngFor="let message of messages" [ngClass]="{ 'd-none' : numAlerts < 1 }">
    <ngb-alert
        [type]="message.type"
        (closed)="closeAlert()">
      {{ message.message }}
    </ngb-alert>
  </div>

  <sb-email-verification-alert *ngIf="!hideEmailVerificationAlert && showEmailVerificationAlert && userEmailAddress"
                               (close)="hideEmailVerificationAlert = true;showEmailVerificationAlert = false"
                               [emailAddress]="userEmailAddress">
  </sb-email-verification-alert>

  <div *ngIf="store && !showEmailVerificationAlert && store.platformConnected && showStoreSyncingAlert"
       class="warning-notification-banner blue-background fade show">
    <div class="row">
      <div class="col-md-9">
        <fa-icon [icon]="['fas', 'spinner']" spin="true" class="mr-2"></fa-icon>
        <span class="font-weight-bold">Store Data Syncing!</span>
        <span>
          We're gathering your data to empower your marketing!
        </span>
      </div>
      <div class="col-md-3 small text-right pr-4">Started {{ store.fullSyncStartedAt | date: 'short' }}</div>
    </div>
  </div>

  <div *ngIf="!store" class="text-center green-color p-5">
    <fa-icon [icon]="['fas', 'cog']" size="3x" [spin]="true"></fa-icon>
  </div>

  <div class="container-fluid pt-2 px-3" style="max-width: 800px">
    <div class="text-right">
      <a *ngIf="showStepper && checklistOptOut" class="cursor-pointer" (click)="unhideStepper()">Show account setup checklist</a>
    </div>
    <sb-stepper [title]="'Account Setup'"
                [showPercentage]="showPercentage"
                [clearThreshold]="50"
                (hideStepper)="hideStepper()"
                [steps]="steps"
                *ngIf="store && showStepper && !checklistOptOut"
                class="animate-fade-in"
                (onClickStep)="onClickStepper($event)">
    </sb-stepper>

    <div class="row my-3">
      <div class="col-md-6">
        <a routerLink="/performance/reviews" *ngIf="store.hasAbrs">
          View monthly reviews
        </a>
      </div>
      <div class="col-md-6">
        <date-range (startDateChange)="startDateChange($event)" (endDateChange)="endDateChange($event)"
                    [startDate]="startDate"
                    [endDate]="endDate" class="w-100">
        </date-range>
      </div>
    </div>

    <!-- Header Section for Top Metrics -->
    <div class="row mb-4">
      <!-- Metric 1: Sales -->
      <div class="col-sm-4 col-6 d-flex" *ngFor="let metric of headerMetrics">
        <div class="card shadow-sm border-0 flex-fill mb-3">
          <div class="card-body d-flex flex-column justify-content-between">
            <!-- Top Section (Label) -->
            <div class="metric-label">
              <h5 class="card-title text-gray-color text-center" style="font-size:1rem">
                {{ metric.label }}
                <fa-icon [icon]="['fas', 'info-circle']" *ngIf="metric.label === 'ROI'" class="blue-color"
                         ngbTooltip="ROI = Return on Investment, and is calculated as (Springbot revenue / Springbot subscription cost) x 100.">
                </fa-icon>
                <a routerLink="/store/settings/integrations" *ngIf="metric.requiresGA && googleConnected === false">
                  <fa-icon [icon]="['fas', 'info-circle']"
                           ngbTooltip="Connect Google Analytics to see this metric.">
                  </fa-icon>
                </a>
              </h5>
            </div>

            <!-- Bottom Section (Value) -->
            <div class="metric-value text-center">
              <p style="font-size:2rem" [ngClass]="metric.color">
                <span *ngIf="metric.requiresGA && googleConnected === false">
                  -
                </span>
                <span *ngIf="!metric.requiresGA || googleConnected">
                  {{ formatValue(metric.value, metric.type) }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-12 p-3">
        <div class="card p-3">
          <email-campaigns-chart [filterOverride]="filterOverride" [embedded]="true"></email-campaigns-chart>
        </div>
      </div>

      <div class="col-xl-12 p-3">
        <div class="card subscriber-growth-chart-card" id="sb-dashboard__subscribers_growth-chart">
          <div class="card-body">
            <subscribers-chart [filterOverride]="filterOverride"></subscribers-chart>
          </div>
        </div>
      </div>

      <div class="col-12 p-3">
        <email-campaign-accolades [filterOverride]="filterOverride"></email-campaign-accolades>
      </div>

      <div class="col-12 p-3">
        <channels-index-component [embedded]="true" [filterOverride]="filterOverride"></channels-index-component>
      </div>
    </div>
  </div>
</div>
