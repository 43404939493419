import {Component, Input, OnChanges, OnInit} from "@angular/core";
import { ChartsService } from "@app/shared/services/charts.service";
import { ChartFilter } from "@models/automations/chart_filter";
import { Statistic } from "@models/statistic";

@Component({
  selector: "email-campaigns-chart",
  styleUrls: ["./email_campaigns_chart.component.scss"],
  templateUrl: "./email_campaigns_chart.component.html",
})
export class EmailCampaignsChartComponent implements OnInit, OnChanges {
  @Input()
  filterOverride: ChartFilter;

  @Input()
  embedded: boolean;

  filter = new ChartFilter({ startDate: "7_days_ago" });

  storeData: number[] = [];
  barChartLabels: string[] = ['Opens', 'Clicks', 'Bounces', 'Unsubscribes'];

  displayData: {
    opens: Statistic;
    clicks: Statistic;
    bounces: Statistic;
    unsubscribes: Statistic;
  };
  loaded = false;
  filterLabel: string = "Last 7 days";

  constructor(private chartsService: ChartsService) {}

  ngOnInit() {
    this.getChartData();
  }

  ngOnChanges() {
    this.getChartData();
  }

  getChartData() {
    this.chartsService
      .getEmailCampaignsData(this.filterOverride || this.filter)
      .subscribe((emailCampaignsDataResponse: any) => {
        const data = emailCampaignsDataResponse.data.emailCampaignGaugeData;

        this.displayData = data;

        this.storeData = [
          data.opens.percentage,
          data.clicks.percentage,
          data.bounces.percentage,
          data.unsubscribes.percentage,
        ];

        this.barChartLabels = [
          `${this.formatValue(data.opens.value, 'number')} Opens`,
          `${this.formatValue(data.clicks.value, 'number')} Clicks`,
          `${this.formatValue(data.bounces.value, 'number')} Bounces`,
          `${this.formatValue(data.unsubscribes.value, 'number')} Unsubscribes`,
        ];

        this.loaded = true;
      });
  }

  setFilter(filter: Partial<ChartFilter>) {
    Object.assign(this.filter, filter);
    this.filterLabel = filter.label;
    this.getChartData();
  }

  formatValue(value: number, type: string): string {
    const formatNumber = (num: number): string => {
      if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M';
      } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K';
      }
      return num.toLocaleString();
    };

    if (type === 'currency') {
      return `$${formatNumber(value)}`;
    }

    if (type === 'percentage') {
      return `${value.toFixed(1)}%`;
    }

    if (type === 'number') {
      return formatNumber(value);
    }

    return value.toString();
  }
}
