import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { StoreService } from "@app/shared/services/store.service";
import { Store } from "@models/store";
import { AppDashboard } from "@models/dashboard/app_dashboard";
import { Recommendation } from "@models/dashboard/recommendation";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";

import { AppDashboardService } from "@app/dashboard/services/app_dashboard.service";

import { UserService } from "@app/shared/services/user.service";
import { User } from "@models/user";
import { BroadcastService } from "@app/shared/services/broadcast.service";
import { BroadcastMessage } from "@models/broadcast_message";
import {StorageService} from "@app/shared/services/storage.service";
import {ChartFilter} from "@models/automations/chart_filter";
import {ReportsService} from "@app/shared/services/reports.service";
import {GoogleAnalyticsConfigurationService} from "@app/shared/services/google_analytics_configuration.service";
import {BillingAccountService} from "@app/stores/services/billing_account.service";
import {BillingAccount} from "@models/billing_account";

@Component({
  selector: "app-dashboard",
  styleUrls: ["./dashboard.component.scss"],
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit, OnDestroy {
  store: Store;
  user: User;
  dash: AppDashboard;
  loading = true;
  recommendations: Recommendation[];
  startDate: Date;
  endDate: Date;
  platformConnected: boolean;
  showBetaToggle: boolean;
  showEmailVerificationAlert: boolean = false;
  hideEmailVerificationAlert: boolean = false;
  showStoreSyncingAlert: boolean = false;
  verificationPoll: any;
  userEmailAddress: string = "";
  redirectingToCampaignBuilder: boolean = false;
  filterOverride: ChartFilter;
  billingAccount: BillingAccount;
  totalSubscriptionCosts: number;

  headerMetrics: any[];

  flash: string[][];
  greeting: string;
  numAlerts: number;
  messages: {
    type: string;
    message: string;
  }[];

  showPercentage: boolean = true;
  steps = [];
  showStepper: boolean = false;
  usersCount: number = 0;
  checklistOptOut: boolean = false;
  performanceMetrics: any;
  googleConnected: boolean;

  constructor(
    private storeService: StoreService,
    private dashService: AppDashboardService,
    private calendar: NgbCalendar,
    private titleService: Title,
    private userService: UserService,
    private router: Router,
    private broadcastService: BroadcastService,
    private storageService: StorageService,
    private reportsService: ReportsService,
    private googleAnalyticsConfigurationService: GoogleAnalyticsConfigurationService,
    private billingAccountService: BillingAccountService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Springbot Dashboard");
    this.endDate = this.convertNgbDateToDate(this.calendar.getToday());
    this.startDate = this.convertNgbDateToDate(this.calendar.getPrev(this.calendar.getToday(), "d", 30));
    this.filterOverride = new ChartFilter({ startDate: this.startDate.toString(), endDate: this.endDate.toString() });
    this.getStore();
    this.getUser();
    this.getDash();
    this.getGreeting();
    this.getPerformanceMetrics();
    // this.storageService.setItem("hideOnboardingChecklist", "false");
    this.broadcastService.listen("emailVerification").subscribe((message: BroadcastMessage) => {
      this.showEmailVerificationAlert = message.payload.verified;
    });
    this.broadcastService.listen("storeUpdated").subscribe((message: BroadcastMessage) => {
      this.store = message.payload.store;
    });
    this.broadcastService.listen("userUpdated").subscribe((message: BroadcastMessage) => {
      this.user = message.payload.user;
    });
    this.broadcastService.listen("hideOnboardingChecklist").subscribe(() => {
      this.checklistOptOut = true;
    });
  }

  ngOnDestroy() {
    if (this.verificationPoll) {
      clearInterval(this.verificationPoll);
    }
  }

  getPerformanceMetrics(): void {
    this.billingAccountService.initializeBilling().subscribe((data) => {
      this.billingAccount = data.billingAccount;
      this.totalSubscriptionCosts = this.billingAccount.totalSubscriptionCosts;
      this.reportsService.stats("store", {
        startDate: this.startDate.toDateString(),
        endDate: this.endDate.toDateString(),
        metric: "views",
      }).subscribe((response) => {
        this.googleConnected = response.googleAuthed;
        this.performanceMetrics = response;
        let conversionRate = 0;
        if (response.orders && response.views) {
          conversionRate = (response.orders / response.views) * 100;
        }
        let roi: number;
        if (response.springbotRevenue === 0 || this.totalSubscriptionCosts === 0) {
          roi = 0;
        } else {
          roi = (response.springbotRevenue / this.totalSubscriptionCosts) * 100;
        }
        this.headerMetrics = [
          { label: 'Total Revenue', value: response.revenue, color: 'text-primary', type: 'currency' },
          { label: 'Springbot Revenue', value: response.springbotRevenue, color: 'text-success', type: 'currency' },
          { label: 'ROI', value: roi, color: 'gold-medium-color', type: 'percentage' },
          { label: 'Views', value: response.views, color: 'blue-medium-color', type: 'number', requiresGA: true },
          { label: 'Orders', value: response.orders, color: 'glacier-medium-color', type: 'number' },
          { label: 'Conversion Rate', value: conversionRate, color: 'green-dark-color', type: 'percentage', requiresGA: true },
        ];
      });
    });
  }

  startDateChange(date: Date): void {
    this.startDate = date;
    this.filterOverride.startDate = date.toString();
    this.filterOverride = new ChartFilter({ startDate: this.startDate.toString(), endDate: this.endDate.toString() });
    this.getPerformanceMetrics();
  }

  endDateChange(date: Date): void {
    this.endDate = date;
    this.filterOverride.endDate = date.toString();
    this.filterOverride = new ChartFilter({ startDate: this.startDate.toString(), endDate: this.endDate.toString() });
    this.getPerformanceMetrics();
  }

  closeAlert(): void {
    this.numAlerts -= 1;
  }

  getGreeting(): void {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      this.greeting = "Good morning";
    } else if (hour >= 12 && hour < 17) {
      this.greeting = "Good afternoon";
    } else if (hour >= 17 && hour < 24) {
      this.greeting = "Good evening";
    } else {
      this.greeting = "Hello";
    }
  }

  getStore(): void {
    this.storeService.getStore().subscribe((response) => {
      this.store = response;
      this.platformConnected = response.platformConnected;
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      this.showStoreSyncingAlert = this.store.harvesting && this.store.fullSyncStartedAt > oneWeekAgo;
      this.loading = false;
      this.getUsers();
      this.getChecklist();
      if (this.store?.frontendBetaEnabled && !this.store?.frontendBetaForced) {
        this.showBetaToggle = true;
      }
    });
  }

  getDash(): void {
    const params = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.dashService.show(params).subscribe((response) => {
      this.dash = response;
      this.flash = this.dash.flash;
      this.messages = this.flash.map((flashPair) => ({
        type: flashPair[0] === 'notice' ? 'success' : 'danger',
        message: flashPair[1],
      }));
      this.numAlerts = this.flash.length;
      this.recommendations = this.dash.recommendations;
      this.loading = !(this.store && this.dash);
    });
  }

  getUser(): void {
    this.userService.show("current", {}).subscribe((user) => {
      this.userService.setUser(user);
      this.user = user;
      this.userEmailAddress = this.user.email;
      this.showEmailVerificationAlert = this.user && !this.user.confirmed;
      if (this.showEmailVerificationAlert && !this.verificationPoll) {
        this.verificationPoll = setInterval(() => {
          this.getUser();
        }, 30000);
      } else if (this.verificationPoll && !this.showEmailVerificationAlert) {
        clearInterval(this.verificationPoll);
      }
    });
  }

  convertNgbDateToDate(date: NgbDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  private getUsers(): void {
    this.userService.index({ non_owners: "true"}).subscribe(
      (response) => {
        this.usersCount = response.data.length;
      }
    );
  }

  onClickStepper(step) {
    this.router.navigateByUrl(step.link);
  }

  getChecklist(): void {
    this.checklistOptOut = this.storageService.getItem(`hideOnboardingChecklist${this.store.id}`) == "true"
  }

  unhideStepper(): void {
    this.checklistOptOut = false;
    this.storageService.setItem(`hideOnboardingChecklist${this.store.id}`, "false");
    this.broadcastService.publish({ type: "hideOnboardingChecklist", payload: { hideOnboardingChecklist: false }});
  }

  hideStepper(): void {
    this.checklistOptOut = true;
    this.storageService.setItem(`hideOnboardingChecklist${this.store.id}`, "true");
    this.broadcastService.publish({ type: "hideOnboardingChecklist", payload: { hideOnboardingChecklist: true }});
  }

  formatValue(value: number, type: string): string {
    const formatNumber = (num: number): string => {
      if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M';
      } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K';
      }
      return num.toLocaleString();
    };

    if (type === 'currency') {
      return `$${formatNumber(value)}`;
    }

    if (type === 'percentage') {
      return `${value.toFixed(1)}%`;
    }

    if (type === 'number') {
      return formatNumber(value);
    }

    return value.toString();
  }
}
