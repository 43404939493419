import { Component, Input } from '@angular/core';
import { EmailCampaign } from '@models/email_campaign';
import { EmailCampaignsService } from '@app/email_campaigns/services/email_campaigns.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

export interface ITemplateData {
  name: string;
  open: number;
  clicks: number;
  revenue: number;
}

@Component({
  selector: 'email-campaigns-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
  host: { 'class': "col-md-6 col-sm-12 pt-3" }
})
export class TemplateCardComponent {

  @Input()
  cardName: string;

  @Input()
  info: string;

  @Input()
  campaign: EmailCampaign;

  @Input()
  data: ITemplateData;

  emailCampaignPreviewContent: string;

  constructor(
    private emailCampaignsService: EmailCampaignsService, 
    private modalService: NgbModal,
    private router: Router) { }

  onPreview(content) {
    this.emailCampaignsService.preview(this.campaign.id).subscribe((preview) => {
      this.emailCampaignPreviewContent = preview.preview;
      this.modalService.open(content, { size: "lg" });
    });
  }

  onDuplicate() {
    this.emailCampaignsService.duplicate(this.campaign.id).subscribe((emailCampaignDuplicate) => {
      this.router.navigateByUrl(`/email_campaigns/edit/campaign/${emailCampaignDuplicate.id}`);
    });
  }

  onReview() {
    this.router.navigateByUrl(`/email_campaigns/edit/campaign/${this.campaign.id}/review`);
  }

  onFullAnalysis() {
    this.router.navigateByUrl(`/actions/${this.campaign.actionId}`);
  }
}
