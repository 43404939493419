import { Component, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/auth/services/auth.service";
import { TokenCheckResponse } from "@app/auth/services/token_check_response";

import { Title } from "@angular/platform-browser";
import * as FullStory from '@fullstory/browser';
import { environment } from '@environments/environment';

// Font Awesome Stuff
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";

import { StoreService } from "@app/shared/services/store.service";
import { Store } from "@models/store";
import Bugsnag from "@bugsnag/js";
import { UserService } from "@app/shared/services/user.service";

import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {StorageService} from "@app/shared/services/storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent {
  private tokenCheckId: any;
  private store: Store;

  @ViewChild('forceLogoutModal') forceLogoutModal: TemplateRef<any>;
  loginErrorMessage: string = "Something went wrong.";
  redirectTo: string = "/login";
  showingForceLogoutModal = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private faLib: FaIconLibrary,
    private titleService: Title,
    private storeService: StoreService,
    private userService: UserService,
    private storageService: StorageService,
  ) {
    FullStory.init({ orgId: 'springbot.com',
      devMode: !environment.production });

    this.addFaIcons();
  }

  ngOnInit() {
    this.titleService.setTitle("Springbot");
    this.storeService.getStore().subscribe((store) => {
      this.store = store;
    });
    this.runTokenCheck();
    this.tokenCheckId = setInterval(() => {
      this.runTokenCheck();
    }, 30000);

    Bugsnag.addOnError((event) => {
      event.addMetadata('fullStory', this.getBugsnagSessionData());
      event.addMetadata('user', this.getBugsnagUserData());
      event.addMetadata('store', this.getBugsnagStoreData());
    });
  }

  ngOnDestroy() {
    if (this.tokenCheckId) {
      clearInterval(this.tokenCheckId);
    }
  }

  relog() {
    this.router.navigateByUrl(this.redirectTo);
  }

  private runTokenCheck() {
    // This is an intermittent check to make sure the token gets refreshed even if the user is idle.
    // We don't want this to redirect when the token is blank or else the user can get stuck in a redirect loop.
    if (this.currentRouteRequiresAuthentication()) {
      this.authService.tokenCheck().subscribe((response: TokenCheckResponse) => {
        if (response.error && response.error === "not logged in") {
          this.relog();
        } else if (response.error && !this.showingForceLogoutModal) {
          console.error("Heartbeat auto logout:", response.error);
          this.loginErrorMessage = response.error;
          const redirectTo = this.route.snapshot.queryParams.redirectTo;
          if (!redirectTo && this.router.url !== "/") {
            this.redirectTo = this.router.url.length > 1 ? `/login?redirectTo=${this.router.url}` : '/login'
          }
        }
      });
    }
  }

  // TODO: we need a better way to do this.
  private currentRouteRequiresAuthentication(): boolean {
    return !(window.location.href.indexOf("/login") > -1 ||
        window.location.href.indexOf("/password_reset") > -1 ||
        window.location.href.indexOf("/register") > -1 ||
        window.location.href.indexOf("/email_verified") > -1 ||
        window.location.href.indexOf("/verify/") > -1);
  }

  private getBugsnagSessionData(): any {
    return {
      url: FullStory.getCurrentSessionURL(true),
    }
  }

  private getBugsnagUserData(): any {
    return {
      userId: this.userService.getSource().value?.id,
      userEmail: this.userService.getSource().value?.email,
      context: this.storageService.getItem('bugsnag-context')
    }
  }

  private getBugsnagStoreData(): any {
    return {
      storeId: this.store?.id,
      storeUrl: this.store?.url,
    }
  }

  private addFaIcons(): void {
    this.faLib.addIconPacks(fas, fal, fab);
  }
}
